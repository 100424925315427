@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&family=Manrope:wght@400;600;700;800&family=Poppins:wght@400;600;700&display=swap");


:root {
  /* Typography */
  --family-poppins: "Poppins", sans-serif;
  --family-ubuntu: "Ubuntu", sans-serif;
  --fs-sm: 10px;
  --fs-md: 14px;
  --fs-mdbig: 16px;
  --fs-lg: 32px;
  --fw-light: 300;
  --fw-normal: 400;
  --fw-semi-bold: 700;
  --fw-bold: 800;

  /* Other */
  --radii: 0.5rem;

  /*Text Colors*/
  --ordinary-text: #ffffff;
  --blue-text: #0098ff;
  --grey-text: #a0acc9;
  --black-text: #000000;
}

body {
  color: var(--black-text);
  font-family: var(--family-poppins);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
